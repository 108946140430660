<template>
  <div class="login-wrap">
    <div class="wx-login">
      <Tabs value="bound" :animated="false" @on-click="changeTab">
          <TabPane label="绑定已有账号" name="bound">
            <Form ref="formBound" :model="formBound" :rules="ruleBound" @submit.native.prevent>
              <FormItem prop="loginAccount">
                <Input
                  v-model.trim="formBound.loginAccount"
                  placeholder="手机号/用户名/邮箱"
                  style="width: 100%"
                  @on-blur="loginAccountBlur"
                >
                  <img class="icon" src="@/assets/imgs/login/icon-account.png" slot="prefix">
                </Input>
              </FormItem>
              <FormItem prop="pwd">
                <Input
                  v-model.trim="formBound.pwd"
                  type="password"
                  password
                  placeholder="请输入密码"
                  style="width: 100%"
                >
                  <img class="icon" src="@/assets/imgs/login/icon-password.png" slot="prefix">
                </Input>
              </FormItem>
            </Form>
            <Button class="btn" type="error" @click="boundLogin" long  :disabled="btnDisabled">立即绑定</Button>
          </TabPane>
        <TabPane label="手机验证码" name="code">
          <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" @submit.native.prevent>
            <FormItem prop="mobile">
              <Input
                v-model.trim="formValidate.mobile"
                placeholder="手机号"
                maxlength="11"
                style="width: 100%"
                clearable
                @input.native="formValidate.mobile = formValidate.mobile.replace(/\D/g, '')"
                @on-blur="phoneBlur"
              >
                <img class="icon" src="@/assets/imgs/login/icon-account.png" slot="prefix">
              </Input>
            </FormItem>
          </Form>
          <div class="code-btn-wrap">
            <Input
              v-model.trim="validateCode"
              prefix="ios-contact"
              placeholder="验证码"
              type="text"
              style="flex:1;margin-bottom: 0;margin-right:10px"
            >
               <img class="icon" src="@/assets/imgs/login/icon-code.png" slot="prefix">
            </Input>
            <div class="code-btn" @click="getValidateCode" v-if="checkFlag">{{firstFlag?'获取验证码':'重新发送'}}</div>
            <div class="countDown-btn" v-else>倒计时{{countDownTime}}s</div>
          </div>
          <span style="font-size:12px;color:#666">未注册的手机号验证后即完成注册</span>
          <Button class="btn" type="error" @click="validateLogin" long>登录</Button>
        </TabPane>
        <TabPane label="注册新账号" name="register">
          <Form ref="formRegister" :model="formRegister" :rules="ruleRegister" @submit.native.prevent>
            <FormItem prop="mobile">
              <Input
                v-model.trim="formRegister.mobile"
                placeholder="手机号"
                maxlength="11"
                style="width: 100%"
                clearable
                @input.native="formRegister.mobile = formRegister.mobile.replace(/\D/g, '')"
                @on-blur="registerPhoneBlur"
              >
                <img class="icon" src="@/assets/imgs/login/icon-account.png" slot="prefix">
              </Input>
            </FormItem>
          <div class="code-btn-wrap">
            <Input
              v-model.trim="registerCode"
              prefix="ios-contact"
              placeholder="验证码"
              type="text"
              style="flex:1;margin-bottom: 0;margin-right:10px"
            >
               <img class="icon" src="@/assets/imgs/login/icon-code.png" slot="prefix">
            </Input>
            <div class="code-btn" @click="getRegisterCode" v-if="registerCheckFlag">{{registerFirstFlag?'获取验证码':'重新发送'}}</div>
            <div class="countDown-btn" v-else>倒计时{{countRegisterDownTime}}s</div>
          </div>
            <FormItem prop="pwd">
              <Input
                v-model.trim="formRegister.pwd"
                type="password"
                password
                placeholder="8-16位密码，区分大小写"
                style="width: 100%"
              >
                <img class="icon" src="@/assets/imgs/login/icon-password.png" slot="prefix">
              </Input>
            </FormItem>
            <FormItem prop="passwdCheck">
              <Input
                v-model.trim="formRegister.passwdCheck"
                type="password"
                password
                placeholder="确认密码"
                style="width: 100%"
              >
                <img class="icon" src="@/assets/imgs/login/icon-password.png" slot="prefix">
              </Input>
            </FormItem>
          </Form>
          <Button class="btn" type="error" @click="registerLogin" long>注册并绑定微信</Button>
        </TabPane>
      </Tabs>
      <div class="rules">
        <Checkbox v-model="single">
          同意
          <span @click="$router.push('/registerAgreement')" :style="{color:single ? '#0085ff':'#999'}">《用户协议》</span>
          和
          <span @click="$router.push('/privacyAgreement')"  :style="{color:single ? '#0085ff':'#999'}">《隐私声明》</span>
        </Checkbox>
      </div>
      <div id="captcha"></div>
    </div>
  </div>
</template>

<script>
import { accountLogin,getCode,codeLogin,checkLogin, wechatBindByPsw,wechatBindBySms, wechatBindRegiste, checkMobileExist} from '@/api/api'
import { rasEncrypt ,checkLoginConfig} from '@/utils/common'
export default {
  name: 'Login',
  data() {
    const validatePhone = (rule, value, callback) => {
      const regPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if(regPhone.test(value)){
        callback()
      }else{
        callback(new Error('请输入正确的手机号码！'))
      }
    }
    const validateEmailPhone = (rule, value, callback) => {
      const regEmailPhone = /^[a-zA-Z0-9\.@]+$/
      if(regEmailPhone.test(value)){
      checkMobileExist({mobile: value}).then(res => {
        if (res.result === 0) {
          callback()
        } else {
          callback(new Error('账号不存在'))
        }
      })
      }else{
        callback(new Error('请输入正确的账户名！'))
      }
    }
    const validatePwd = (rule, value, callback) => {
      const regPwd = /^[a-zA-Z0-9]{8,16}$/
      if(regPwd.test(value)){
        callback()
      }else{
        callback(new Error('请输入正确的密码！'))
      }
    }
    const validatePassCheck = (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请输入密码'));
        } else if (value !== this.formRegister.pwd) {
            callback(new Error('两次输入密码不一致！'));
        } else {
            callback();
        }
    };
    return {
      // single: true,
      tabName:'bound',
      validateCode:'',
      registerCode: '',
      countDownTime:null,
      countRegisterDownTime: null,
      firstFlag:true,
      registerFirstFlag: true,
      checkFlag:true,
      registerCheckFlag: true,
      captchaIns:null, //网易网盾插件实例
      registerCaptchaIns:null, //网易网盾插件实例
      // passwordFrom:{
      //   account:'',
      //   password:''
      // },
      formBound: {loginAccount: '', pwd: ''},
      ruleBound: {
        loginAccount: [
          { required: true, message: '请输入账户名', trigger: 'blur' },
          { validator: validateEmailPhone, trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }]
      },
      formRegister: {mobile: '', pwd: '' },
      ruleRegister: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        pwd: [{ required: true, message: '请输入密码', trigger: 'blur' }, { validator: validatePwd, trigger: 'blur' }],
        passwdCheck: [{ validator: validatePassCheck, trigger: 'blur' }]
      },
      formValidate:{
        mobile:'',
      },
      ruleValidate:{
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ]
      },
      errorKey: '', // 未绑定的用户扫码都会返回errorKey|| wechat_login_key1111
      single: false,
      mobileIsExist: false
    }
  },
  computed: {
    btnDisabled() {
      return !(this.single  && this.formBound.pwd && this.mobileIsExist && this.formBound.loginAccount)
    },
  },
  created() {
    this.getUrlInfo()
  },
  mounted() {
    // 网易网盾配置-手机验证码
    checkLoginConfig({
      init: (instance) => {
        // 初始化成功后得到验证实例instance，可以调用实例的方法
        this.captchaIns = instance
      },
      afterCheck: (val) => {
        // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
        this.captchaIns.close()
        checkLogin({
          captchaValidateValue: val.validate,
          mobileNumber: this.tabName === 'code' ? this.formValidate.mobile : this.formRegister.mobile
        }).then((res) => {
          if(res.result === 0){
            // this.validateLogin()
            this.tabName === 'code' ? this.getValidateCode() : this.getRegisterCode()
          } else {
            this.$Message.error({
              content: res.message,
              duration: 1
            })
          }
        })
      },
    })
    // 网易网盾配置-手机验证码
    // checkRegisterLoginConfig({
    //   init: (instance) => {
    //     // 初始化成功后得到验证实例instance，可以调用实例的方法
    //     this.registerCaptchaIns = instance
    //   },
    //   afterCheck: (val) => {
    //     // 验证成功后，调用close方法关闭弹框（enableClose为true时调用）
    //     this.registerCaptchaIns.close()
    //     checkLogin({
    //       captchaValidateValue: val.validate,
    //       mobileNumber: this.formRegister.mobile
    //     }).then((res) => {
    //       if(res.result === 0){
    //         // this.validateLogin()
    //       } else {
    //         this.$Message.error({
    //           content: res.message,
    //           duration: 1
    //         })
    //       }
    //     })
    //   },
    // })
  },
  methods: {
    async getUrlInfo() {
      let localUrl = decodeURIComponent(window.location.href)
      let paramsInfo = {}
      let tempArr = []
      if (localUrl.indexOf('?') != -1) {
        let str =
          localUrl.replace(/^([^\?]*).*$/, function ($all, $1) {
            return $1
          }) + '?'
        let strs = localUrl.replace(str, '').split('&')
        if (strs.length > 2) {
          tempArr = strs.slice(1, strs.length - 1)
        }
        // 切除callBack=字段
        for (var i = 0; i < strs.length; i++) {
          paramsInfo[
            strs[i].replace(/^([^\=]*).*$/, function ($all, $1) {
              return $1
            })
          ] = strs[i].replace(
            strs[i].replace(/^([^\=]*).*$/, function ($all, $1) {
              return $1
            }) + '=',
            '',
          )
        }
      }
      //微信测试登录
      this.errorKey = paramsInfo.errorKey ? paramsInfo.errorKey : ''
    },
    changeTab (name) {
      console.log(name)
      this.tabName = name
      this.single = false
    },
    async loginAccountBlur (e) {
      const { result } = await checkMobileExist({
        mobile: this.formBound.loginAccount
      })
      this.mobileIsExist = result === 0
      // this.mobileIsExist = true
    },
    // 已绑定用户登录
    async boundLogin () {
      if (!this.single) {
        this.$Message.error({
          content: '请勾选协议',
          duration: 2,
        })
        return
      }
      this.$refs.formBound.validate(async valid => {
        if(!valid) return
        const { result, message, data } = await wechatBindByPsw({
          loginAccount: this.formBound.loginAccount,
          pwd: rasEncrypt(this.formBound.pwd),
          loginFlag: 'pcMall',
          errorKey: this.errorKey
        })
        if(result === 0){
          this.$store.commit('SET_USER_INFO', data)
          this.$emit('cart');
          this.$router.replace({ path: '/home' })
        }else{
          this.$Message.error({
            content: message,
            duration: 2,
          })
        }
      })
    },
    // 手机验证码登录
    async validateLogin () {
      if (!this.single) {
        this.$Message.error({
          content: '请勾选协议',
          duration: 2,
        })
        return
      }
      this.$refs.formValidate.validate(async valid => {
        if(!valid) return
        const {result,message,data} = await wechatBindBySms({
          mobile: this.formValidate.mobile,
          smsCode: this.validateCode,
          loginFlag: 'pcMall',
          errorKey: this.errorKey
        })
        if(result === 0){
          this.$store.commit('SET_USER_INFO', data)
          this.$emit('cart');
          this.$router.replace({ path: '/home' })
        }else{
          this.$Message.error({
            content: message,
            duration: 2,
          })
        }
      })
    },
    // 注册登录
    registerLogin () {
      if (!this.single) {
        this.$Message.error({
          content: '请勾选协议',
          duration: 2,
        })
        return
      }
      this.$refs.formRegister.validate(async valid => {
        if(!valid) return
        const {result,message,data} = await wechatBindRegiste({
          mobile: this.formRegister.mobile,
          pwd: this.formRegister.pwd,
          smsCode: this.registerCode,
          loginFlag: 'pcMall',
          errorKey: this.errorKey
        })
        if(result === 0){
          this.$store.commit('SET_USER_INFO', data)
          this.$emit('cart');
          this.$router.replace({ path: '/home' })
        }else{
          this.$Message.error({
            content: message,
            duration: 2,
          })
        }
      })
    },
    // /* 账号密码登录 */
    // async accountLogin(){
    //   this.$refs.passwordValidate.validate(async valid => {
    //     if(!valid) return
    //     const { result, message, data } = await accountLogin({
    //       loginAccount: this.passwordFrom.account,
    //       pwd: rasEncrypt(this.passwordFrom.password),
    //       loginFlag: 'pcMall',
    //     })
    //     if(result === 0){
    //       this.$store.commit('SET_USER_INFO', data)
    //       this.$emit('cart');
    //       this.$router.back()
    //     }else{
    //       this.$Message.error({
    //         content: message,
    //         duration: 2,
    //       })
    //     }
    //   })
    // },
    /* 验证码登录 */
    // async codeLogin(){
    //   this.$refs.formValidate.validate(async valid => {
    //     if(!valid) return
    //     const {result,message,data} = await codeLogin({
    //       mobile: this.formValidate.mobileNum,
    //       smsCode: this.code,
    //       loginFlag: 'pcMall',
    //     })
    //     if(result === 0){
    //       this.$store.commit('SET_USER_INFO', data)
    //       this.$emit('cart');
    //       this.$router.back()
    //     }else{
    //       this.$Message.error({
    //         content: message,
    //         duration: 2,
    //       })
    //     }
    //   })
    // },

    // /* 微信登录 */
    // weChatLogin(){
    //   console.log('微信登录');
    //   //微信登录先扫码，若未绑定，则跳到未绑定页面。若已绑定，则跳到登录界面
    //   this.codeDialog = true
    // },

    // /* 忘记密码 */
    // forgetPassword() {
    //   this.$router.push('/forget-password')
    // },

    /* 手机号失焦 */
    phoneBlur(){
      this.$refs.formValidate.validateField('mobile',async valid => {
        if(!valid && !this.firstFlag){
          this.firstFlag = true
        }
      })
    },
    registerPhoneBlur () {
      this.$refs.formRegister.validateField('mobile',async valid => {
        if(!valid && !this.registerFirstFlag){
          this.registerFirstFlag = true
        }
      })
    },
    /* 手机登录倒计时 */
    timeCountDown(time){
      this.countDownTime = time
      let timer = setInterval(() => {
        this.countDownTime -=1
        if(this.countDownTime <= 0){
          this.checkFlag = true
          clearInterval(timer)
        }
      },1000)
    },
    // 注册短信倒计时
    timeRegisterCountDown (time) {
      this.countRegisterDownTime = time
      let timer = setInterval(() => {
        this.countRegisterDownTime -=1
        if(this.countRegisterDownTime <= 0){
          this.registerCheckFlag = true
          clearInterval(timer)
        }
      },1000)
    },
    // 获取手机验证码
    getValidateCode () {
      this.$refs.formValidate.validateField('mobile',async valid => {
        if(valid) return
        const {result,message} =  await getCode({ mobileNumber: this.formValidate.mobile, messageFlag: 'PC_MALL_WECHAT_LOGIN_MOBILE_BIND' })
        switch (result) {
          case 0:
            this.$Message.success({
              content: '短信验证码发送成功',
              duration: 2,
            })
            this.checkFlag = false
            this.firstFlag = false
            this.timeCountDown(60)
            break;
          case 2233:
          case 2232:
            this.$Message.warning({
              content: message,
              duration: 2,
            })
            break
          case 2231: //人工滑块
             this.captchaIns && this.captchaIns.popUp()
            break
          default:
            break;
        }
      })
    },
    // 新注册验证短信
    getRegisterCode () {
      this.$refs.formRegister.validateField('mobile',async valid => {
        if(valid) return
        const {result,message} =  await getCode({ mobileNumber: this.formRegister.mobile, messageFlag: 'PC_MALL_WECHAT_LOGIN_MOBILE_REGISTER' })
        switch (result) {
          case 0:
            this.$Message.success({
              content: '短信验证码发送成功',
              duration: 2,
            })
            this.registerFirstFlag = false
            this.registerCheckFlag = false
            this.timeRegisterCountDown(60)
            break;
          case 2233:
          case 2232:
            this.$Message.warning({
              content: message,
              duration: 2,
            })
            break
          case 2231: //人工滑块
            this.captchaIns && this.captchaIns.popUp()
            break
          default:
            break;
        }
      })
    },
    /* 获取验证码 */
    // getCode(){
    //   this.$refs.formValidate.validateField('mobile',async valid => {
    //     if(valid) return
    //     const {result,message} =  await getCode({ mobileNumber: this.formValidate.mobileNum, messageFlag: 'PC_SMS_LOGIN' })
    //     switch (result) {
    //       case 0:
    //         this.$Message.success({
    //           content: '短信验证码发送成功',
    //           duration: 2,
    //         })
    //         this.checkFlag = false
    //         this.firstFlag = false
    //         this.timeCountDown(60)
    //         break;
    //       case 2233:
    //       case 2232:
    //         this.$Message.warning({
    //           content: message,
    //           duration: 2,
    //         })
    //         break
    //       case 2231: //人工滑块
    //         this.captchaIns.popUp()
    //         break
    //       default:
    //         break;
    //     }
    //   })
    // },
  },
}
</script>
<style lang="less" scoped>
.login-wrap {
  width: 100%;
  position: relative;
  height: 500px;
  background-color: gray;
  background: url('https://mall.gree.com/assets/images/login_bg_normal.png')
    no-repeat;
  background-size: 100% 100%;
}
.rules {
  margin-top: 20px;
}
.wx-login {
  position: absolute;
  top: 50%;
  right: 150px;
  transform: translateY(-50%);
  width: 420px !important;
  height: 334px;
  background-color: #fff;
  padding: 30px 26px 10px;
}
.btn {
  height: 40px;
  font-size: 14px;
}
.icon{
  width: 16px;
  margin-top: 12px;
}
/deep/.ivu-input-prefix{
  line-height: 36px;
}

/deep/.ivu-tabs {
  color: #000;
  .ivu-tabs-bar {
    border: none;
    margin-bottom: 20px;
    .ivu-tabs-nav{
      width: 100%;
      display: flex;
      justify-content: center;
      .ivu-tabs-tab{
        font-size: 16px;
      }
      .ivu-tabs-ink-bar{
        display: none;
      }
      .ivu-tabs-tab-focused{
        border-bottom: 3px solid red !important;
      }
    }
    .ivu-tabs-tab-active {
      color: red;
    }
    .ivu-tabs-ink-bar {
      background-color: red;
    }
  }
  .ivu-input {
    // width: 90%;
    height: 40px !important;
  }
  .ivu-input-wrapper {
    display: block;
    margin-bottom: 25px;

  }
}

.forget-password {
  color: #1890FF;
  text-align: right;
  width: 100%;
  cursor: pointer;
  font-size: 12px;
}

/deep/.ivu-btn {
  width: 100%;
  margin-top: 10px;
}
/deep/.ivu-form-item{
  margin-bottom: 0;
}
.code-btn-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .code-btn {
    width: 100px;
    height: 34px;
    border: 1px solid #dcdee2;
    text-align: center;
    line-height: 34px;
    cursor: pointer;
    color: #ef2f2f;
    font-size: 14px;
  }
  .countDown-btn{
    width: 100px;
    height: 34px;
    text-align: center;
    line-height: 34px;
    color: #ef2f2f;
    border: 1px solid #dcdee2;
    font-size: 14px;
  }
}

.other-methods {
  margin: 15px 0;
  font-size: 12px;
  img{
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-left: 5px;
    cursor: pointer;
  }
}

.rules {
  text-align: center;
}
/deep/.ivu-checkbox-wrapper{
  font-size: 13px;
}
.codeDialog {
  text-align: center;
  .title {
    font-size: 20px;
  }
}
</style>